<template>
  <div>
    <destino-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-input-group>
                <flat-pickr
                  v-model="selectFecha"
                  placeholder="Buscar por fecha Registro"
                  class="form-control"
                />
                <b-input-group-append>

                  <b-button

                    variant="primary"
                    type="button"
                    title="Limpiar"
                    @click="clearFilters"
                  >
                    <feather-icon
                      icon="XIcon"

                      @click="clearFilters"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="selectTipoVenta.text"
                right
                variant="outline-primary"
                class="ml-1 mr-1"
              >
                <b-dropdown-item
                  v-for="sortOption in listVentas"
                  :key="sortOption.value"
                  @click="selectTipoVenta = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(f_registro)="data">
          <span class="d-block text-nowrap ">Del {{ data.item.desde }} al {{ data.item.hasta }}</span>
          <span class="text-muted">Fecha registro: {{ formatFecha(data.item.fechaRegistro) }}</span>
        </template>

        <template #cell(turista)="data">
          <b-media vertical-align="center">
            <template #aside>
              <!--  <b-avatar
                size="32"
                :variant="`light-${getColorAvatar()}`"
              >
                <font-awesome-icon
                  :icon="data.icono || 'fa-solid fa-list'"
                />
              </b-avatar> -->
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.nombres }} {{ data.item.apellidos }}
            </span>
            <span class="font-weight-bold d-block text-nowrap text-muted d-block">
              {{ data.item.email }}
            </span>
            <span class="text-muted d-block ">
              {{ data.item.celular }}
            </span>

          </b-media>
        </template>
        <template #cell(items)="data">
          <span
            v-for="(item, index) in data.item.items"
            :key="item.nombre"
          >
            <span
              v-if="index == 0"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ item.nombre }}
            </span>
            <b-badge
              v-if="index > 0"
              pill
              :variant="item.color"
              class="text-capitalize"
              style="margin-right: 3px;"
            >
              {{ item.nombre }}
            </b-badge>
          </span>

        </template>
        <template #cell(venta)="data">
          <b-badge
            v-if="data.item.ventaConcretada === 'N'"
            pill
            variant="light-info"
            class="text-capitalize"
            @click="confirmReserva(data.item)"
          >
            Venta por concretar
          </b-badge>

          <b-badge
            v-else
            pill
            variant="light-success"
            class="text-capitalize"
          >
            Venta Concretada
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="getEdit(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getDelete(data)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BBadge, BDropdown, BDropdownItem, BPagination, BButton, BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BMedia, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { showToast, showError } from '@/helpers'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import DestinoForm from './DestinoForm.vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    flatPickr,
    vSelect,
    BSpinner,
    DestinoForm,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    isBusy: false,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    selectFecha: '',
    selectTipoVenta: { value: '', text: 'Todos' },
    items: [],
    listVentas: [
      { value: '', text: 'Todos' },
      { value: 'N', text: 'Venta por concretar' },
      { value: 'S', text: 'Venta Concretada' },
    ],
    listColors: [
      'light-info',
      'light-danger',
      'light-primary',
      'light-warning',
      'light-success'],
    fields: [
      { key: 'f_registro', label: 'Fecha Servicio / Registro', sortable: true },
      { key: 'turista', label: 'Datos Turista', sortable: true },
      { key: 'items', label: 'Items', sortable: true },
      { key: 'mensaje', label: 'mensaje', sortable: true },
      { key: 'venta', label: 'Estado Venta', thClass: 'text-center' },
    ],
    price: null,
  }),

  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    /* listventas watcher */
    selectTipoVenta: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        /* timer 5 seg */
        setTimeout(() => {
          this.listarRegistros()
        }, 1000)
        // this.listarRegistros()
      },
    },
    selectFecha: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  async mounted() {
    await this.listarRegistros()
  },
  methods: {
    avatarText,
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    clearFilters() {
      this.selectFecha = ''
    },
    splitearDatos(item) {
      let spliteadoInformacion = []
      if (item !== null) {
        spliteadoInformacion.push(item)
        if (item.includes('<br />')) {
          spliteadoInformacion = item.split('<br />')
          return spliteadoInformacion
        }
      }

      return spliteadoInformacion
    },
    splitearFechaServicio(item) {
      let spliteadoInformacion = []
      spliteadoInformacion.push(item)
      if (item.includes(',')) {
        spliteadoInformacion = item.split(',')
        return spliteadoInformacion
      }
      return spliteadoInformacion
    },
    async listarRegistros() {
      this.isBusy = true
      this.items = []
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      await store
        .dispatch('panel/RESERVAS_FIND_ALL', {
          limit: this.perPage,
          query: this.query,
          page: this.page,
          ventaConcretada: this.selectTipoVenta.value,
          fecha: this.selectFecha,
          // sortBy: 'idDestinoTuristico%7CDESC',
          idDirectorio: directorio.idDirectorio,
        })
        .then(response => {
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
          response.items.forEach(reservas => {
            const spliteadoDatos = this.splitearDatos(reservas.datos)
            let [desde, hasta] = this.splitearFechaServicio(reservas.fechaServicio)
            desde = this.formatFecha(desde)
            hasta = this.formatFecha(hasta)
            const split = []
            let numero = 0
            spliteadoDatos.forEach(corte => {
              split.push({ nombre: corte, color: this.listColors[numero] })
              numero += 1
              if (numero === 5) {
                numero = 0
              }
            })
            this.items.push({ ...reservas, items: split, desde, hasta })
          })
        })
        .catch(error => {
          console.log(error)
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    confirmReserva(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea concretar la reserva?',
        icon: 'info',

        html: `<span>¿Desea concretar la reserva? </span>
      <br/>  
      <input
      type="number"
      placeholder="Ingrese el precio de reserva"
      value="${this.price}"
      class="swal2-input"
      id="price">`,
        /*     input: 'number',
        inputValue: this.price, */

        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        /* inputAttributes: {
          placeholder: 'Ingrese el precio de reserva',
        }, */
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const price = document.getElementById('price').value
          if (price !== '') {
            await this.concretarReserva(item.idReserva, price)
          } else {
            this.$bvToast.toast('Debe ingresar un precio', {
              title: 'Ha ocurrido un error',
              variant: 'info',
              solid: true,
            })
          }
        }
      })
    },
    async concretarReserva(idReserva, monto) {
      this.isBusy = true

      await store
        .dispatch('panel/CONCRETAR_RESERVA', {
          idReserva,
          monto,
        })
        .then(() => {
          this.$bvToast.toast('Se ha concretado la venta', {
            title: 'Venta concretada',
            variant: 'success',
            solid: true,
          })
          this.listarRegistros()
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        }).finally(() => {
          this.isBusy = false
        })
    },
    openForm(type) {
      this.formType = type
      this.isToggleSidebar = true
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data.item
    },
    getDelete(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('catalogo/DESTINO_DELETE', {
              id: data.item.idDestinoTuristico,
            })
            .then(response => {
              this.listarRegistros()
              this.$bvToast.toast('Ha Eliminado correctamente el registro.', {
                title: 'Operación Exitosa',
                variant: 'success',
                solid: true,
              })
              return response
            })
            .catch(error => {
              this.$bvToast.toast(error, {
                title: 'Ha ocurrido un error',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getDateFmt(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.w100{
  width: 100px !important;
}
.badge{
  font-size: 75% !important;
}
</style>
